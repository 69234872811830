import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let hasContainer = false;
const options = {
   position: 'top-center',
   autoClose: 5000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined,
};

const setup = () => {
   if (!hasContainer && typeof window !== 'undefined') {
      hasContainer = true;
      ReactDOM.render(
         <ToastContainer toastClassName="c-toast" />,
         document.getElementById('root-toastify'),
      );
   }
};

export const success = (content) => {
   setup();
   toast.success(content, options);
};

export const error = (content) => {
   setup();
   toast.error(content, options);
};
